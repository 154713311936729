/* feature icon circle small */
.Ready-Package-Icon {
  background: url('../../assets/icon/header/ready_package_icon.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
}

.Fixed-Package-Icon {
  background: url('../../assets/icon/header/fixed_package_icon.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
}

.Promo-Package-Icon {
  background: url('../../assets/icon/header/promo_package_icon.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
}
/* feature ison circle 2x */
.Ready-Package-Icon-Circle-2x {
  background: url('../../assets/icon/features/ready_package_circle.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Fixed-Package-Icon-Circle-2x {
  background: url('../../assets/icon/features/fix_package_circle.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Custom-Package-Icon-Circle-2x {
  background: url('../../assets/icon/features/custom_package_circle.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Promo-Package-Icon-Circle-2x {
  background: url('../../assets/icon/features/promo_package_circle.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}
/* feature icon plain 2x*/
.Ready-Package-Icon-Plain-2x {
  background: url('../../assets/icon/features/ready_package.png');
  background-size: 64px 64px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 64px !important;
  width: 64px !important;
}

.Fixed-Package-Icon-Plain-2x {
  background: url('../../assets/icon/features/fix_package.png');
  background-size: 64px 64px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 64px !important;
  width: 64px !important;
}

.Promo-Package-Icon-Plain-2x {
  background: url('../../assets/icon/features/promo_package.png');
  background-size: 64px 64px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 64px !important;
  width: 64px !important;
}
/* benefit icon 2x */
.Benefit-1-Icon-2x {
  background: url('../../assets/icon/benefit/benefit_1_icon.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Benefit-2-Icon-2x {
  background: url('../../assets/icon/benefit/benefit_2_icon.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Benefit-3-Icon-2x {
  background: url('../../assets/icon/benefit/benefit_3_icon.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Benefit-4-Icon-2x {
  background: url('../../assets/icon/benefit/benefit_4_icon.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Benefit-5-Icon-2x {
  background: url('../../assets/icon/benefit/benefit_5_icon.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.Benefit-6-Icon-2x {
  background: url('../../assets/icon/benefit/benefit_6_icon.png');
  background-size: 60px 60px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 60px !important;
  width: 60px !important;
}

.User-Custom-Icon {
  background: url('../../assets/icon/header/user_icon.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
  top: 8px;
  position: relative;
}

.Language-Icon {
  background: url('../../assets/icon/header/language_icon.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
  top: 8px;
  position: relative;
}

/* activity icon*/
.Check-In-28px {
  background: url('../../assets/icon/activity/check_in.png');
  background-size: 28px 28px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px !important;
  width: 28px !important;
}

.Check-Out-28px {
  background: url('../../assets/icon/activity/check_out.png');
  background-size: 28px 28px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px !important;
  width: 28px !important;
}

.Return-Accommodation-28px {
  background: url('../../assets/icon/activity/return_accommodation.png');
  background-size: 28px 28px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px !important;
  width: 28px !important;
}

.Leave-Accommodation-28px {
  background: url('../../assets/icon/activity/leave_accommodation.png');
  background-size: 28px 28px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px !important;
  width: 28px !important;
}

.Excursion-28px {
  background: url('../../assets/icon/activity/excursion.png');
  background-size: 28px 28px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 28px !important;
  width: 28px !important;
}

.Seater-Icon {
  background: url('../../assets/icon/service/seater.png');
  background-size: 15px 15px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.restorant-speciality {
  background: url('../../assets/icon/service/restorant_speciality.png');
  background-size: 30px 30px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 30px !important;
  width: 30px !important;
}

.hotel-map-marker {
  background: url('../../assets/icon/map/map_hotel.png');
  background-size: 16px 16px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 16px !important;
  width: 16px !important;
}

.badge-check {
  background: url('../../assets/icon/globalIcon/badge_check.png');
  background-size: 15px 15px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.spinner {
  background: url('../../assets/icon/globalIcon/spinner.png');
  background-size: 15px 15px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 15px !important;
  width: 15px !important;
}

.excursion-map-marker {
  background: url('../../assets/icon/map/excursion_black.png');
  background-size: 12px 12px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 12px !important;
  width: 12px !important;
}

.hotelMarker-red {
  background: url('../../assets/icon/map/mapHotelRed.png');
  background-size: 36px 36px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 36px !important;
  width: 36px !important;
}

.speak {
  background: url('../../assets/icon/globalIcon/speak.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
}

.contact {
  background: url('../../assets/icon/service/contact.png');
  /* background-size: 100px 25px !important; */
  background-position: center;
  background-repeat: no-repeat;
  height: 160px !important;
  width: 75px !important;
}

.travel_agent_solid {
  background: url('../../assets/icon/service/travel_agent_solid.png');
  background-size: 25px 25px !important;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px !important;
  width: 25px !important;
}